import React, { Component } from 'react'
import Header from '../components/header/header'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import PageDetailedHeader from '../components/shared/pageDetailedHeader'
import { graphql, StaticQuery } from 'gatsby'
import WorkListItem from '../components/work/workListItem'
import TrackVisibility from 'react-on-screen'

const WorkListWrapper = styled.div`
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.45) 5%,
    rgb(20, 8, 30) 100%
  );
`
const WorkListInner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding-bottom: 120px;
  position: relative;
  top: -80px;
  .pose-wrapper {
    height: 100%;
  }
  @media (max-width: 1280px) {
    margin: 0 20px;
  }
`

class WorkPage extends Component {
  state = {
    showMore: false,
  }

  render() {
    return (
      <Layout lightMode={false}>
        <SEO title="Work" keywords={[`design`, `developer`, `react`]} />
        <Header colour="#F5A623" />
        <StaticQuery
          query={WORK_ALL_ITEMS_CONTENT}
          render={data => (
            <>
              {console.log(data)}
              <PageDetailedHeader
                bg={data.headerBG}
                intro="Creating well thought out experiences with pixel perfect user interface execution is how I enjoy spending most of my time. Below are some of the projects I am most proud of."
                hasOverlap
              />
              <WorkListWrapper>
                <WorkListInner>
                  {data.wp.edges.map(({ node }) => (
                    <TrackVisibility partialVisibility key={node.id}>
                      <WorkListItem data={node} />
                    </TrackVisibility>
                  ))}
                </WorkListInner>
              </WorkListWrapper>
            </>
          )}
        />
      </Layout>
    )
  }
}

const WORK_ALL_ITEMS_CONTENT = graphql`
  query WorkAllItemsListDetails {
    headerBG: file(relativePath: { eq: "page-header-work.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wp: allWordpressWpWork {
      edges {
        node {
          title
          id
          slug
          excerpt
          acf {
            client
            expertise {
              name
              term_id
            }
            platforms {
              name
              term_id
            }
            deliverables {
              name
              term_id
            }
            image_list_bg {
              source_url
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            color
          }
        }
      }
    }
  }
`

export default WorkPage
