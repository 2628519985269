import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import IconArrow from '../../images/icon-readmore-arrow.svg'
import { Link } from 'gatsby'

const WorkItemAnimation = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: -50, opacity: 0 },
})

const WorkItem = styled(Link)`
  background-color: #f2f2f2;
  background-image: url(${props => props.bg});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin: 0 0 40px 0;
  border: 15px solid #f2f2f2;
  transition: all 0.2s ease-in-out;
  padding: 30px 0 30px 30px;
  min-height: 500px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  &:hover {
    border: 3px solid ${props => props.color};
    padding: 42px 0 42px 30px;
    background-size: 102% auto;
    cursor: pointer;
    h2 span {
      background-size: 100% 3px;
    }
    .button {
      border-color: ${props => props.color};
      color: ${props => props.color};
      #BtnMoreArrow {
        stroke: ${props => props.color};
        opacity: 1;
      }
    }
    .deliverables {
      div {
        background: rgba(0, 0, 0, 0.02);
      }
    }
    .content {
      background: #fff;
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: 330px 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    padding: 0;
    background-size: 150% auto;
    &:hover {
      background-size: 152% auto;
      padding: 12px 12px 0 12px;
      .content {
        margin-left: -12px;
        margin-right: -12px;
        padding-bottom: 12px;
      }
    }
  }
`
const Content = styled.div`
  background-color: #f2f2f2;
  color: rgba(0, 0, 0, 0.9);
  border-radius: 2px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 55px;
  align-items: top;
  transition: all 0.2s ease-in-out;
  h2 {
    text-transform: uppercase;
    line-height: 35px;
    padding: 20px;
    margin: 0;
    span {
      background-image: linear-gradient(
        to right,
        ${props => props.color} 0%,
        ${props => props.color} 100%
      );
      background-size: 50px 3px;
      background-position: 0px 100%;
      transition: background-size 0.8s cubic-bezier(0.19, 1, 0.155, 1.01) 0s;
      background-repeat: no-repeat;
    }
  }
  .excerpt {
    padding: 0 20px;
    p {
      margin: 0;
    }
  }
  .deliverables {
    padding: 20px;
    font-size: 12px;
    div {
      margin-right: 10px;
      transition: all 0.3s ease-in-out;
      color: #a8a3af;
      border: 1px solid #a8a3af4f;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
  @media (max-width: 900px) {
    margin-top: 50%;
    grid-template-rows: auto auto auto 43px;
  }
`
const Button = styled.div`
  border-top: 1px solid #a8a3af4f;
  text-transform: uppercase;
  color: #382d4c;
  display: grid;
  grid-template-columns: 1fr 60px;
  width: 100%;
  margin: 0;
  padding: 15px 25px 17px 25px;
  transition: all 0.2s ease-in-out;
  svg {
    position: relative;
    justify-self: end;
    left: 5px;
    top: 1px;
    transition: all 0.2s ease-in-out;
  }
  #BtnMoreArrow {
    transition: all 0.2s ease-in-out;
    stroke: #382d4c;
    opacity: 0.4;
  }
  @media (max-width: 900) {
    padding: 15px 25px 5px 25px;
  }
`

const WorkListItem = ({ data, isVisible }) => (
  <WorkItemAnimation pose={isVisible ? 'open' : 'closed'}>
    <WorkItem
      to={`/work/${data.slug}`}
      bg={data.acf.image_list_bg ? data.acf.image_list_bg.source_url : ''}
      color={data.acf.color}
    >
      <Content color={data.acf.color} className="content">
        <h2>
          <span dangerouslySetInnerHTML={{ __html: data.title }} />
        </h2>
        <div
          className="excerpt"
          dangerouslySetInnerHTML={{ __html: data.excerpt }}
        />
        <div className="deliverables">
          {data.acf.deliverables.map(skill => (
            <div key={skill.term_id}>{skill.name}</div>
          ))}
        </div>
        <Button icon={IconArrow} className="button">
          View Work
          <svg
            width="36px"
            height="19px"
            viewBox="0 0 36 19"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <title>icon-readmore-arrow</title>
            <g
              id="BtnMoreArrow"
              transform="translate(-779.000000, -1308.000000)"
              stroke="#3E2E5C"
              strokeWidth="1.5"
            >
              <g
                id="ArticleItem-Copy"
                transform="translate(100.000000, 962.000000)"
              >
                <g id="Btn-More" transform="translate(544.000000, 330.000000)">
                  <g id="Group" transform="translate(21.000000, 13.000000)">
                    <g
                      id="icon-readmore-arrow"
                      transform="translate(115.000000, 3.000000)"
                    >
                      <path d="M6.5,9.5 L33.3495708,9.5" id="lineLong" />
                      <path
                        d="M24.7146068,0.918189674 L33.5,9.5"
                        id="lineDiagonalTop"
                      />
                      <path
                        d="M24.8333333,9.74957911 L33.5,18.5"
                        id="lineDiagonalBottom"
                        transform="translate(29.000000, 14.000000) scale(1, -1) translate(-29.000000, -14.000000) "
                      />
                      <path
                        d="M2.09090909,9.5 L0.522727273,9.5"
                        id="lineShort"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </Button>
      </Content>
    </WorkItem>
  </WorkItemAnimation>
)

export default WorkListItem
